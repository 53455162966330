import "./css/main.css";
import "mosha-vue-toastify/dist/style.css";

import App from "./App.vue";
import { createApp } from "vue";
import i18n from "./lang";
import router from "./router";
import store from "./store";
import VueEasyLightbox from 'vue-easy-lightbox'

const defaultDocumentTitle = "Livecart";

// TODO: server auth
router.beforeEach((to, from, next) => {
	const publicPages = ["/login", "/register"];

	const authRequired = !publicPages.includes(to.path);
	const loggedIn = localStorage.getItem("user");

	if (authRequired && !loggedIn) {
		next("/login");
	} else {
		next();
	}
});

router.beforeEach((to) => {
	store.dispatch("asideMobileToggle", false);
	store.dispatch("asideLgToggle", false);

	store.dispatch("formScreenToggle", !!to.meta.formScreen);
});

router.afterEach((to) => {
	if (to.meta && to.meta.title) {
		document.title = `${to.meta.title} — ${defaultDocumentTitle}`;
	} else {
		document.title = defaultDocumentTitle;
	}
});

createApp(App)
	.use(i18n)
	.use(store)
	.use(router)
	.use(VueEasyLightbox)
	.mount("#app");
