import ProductsService from "@/services/products.service";
import { createToast } from "mosha-vue-toastify";
import i18n from "../lang";
import router from "../router";

export const product = {
	namespaced: true,
	state: {
		products: [],
		edit: [],
	},
	actions: {
		getProducts({ state }) {
			return ProductsService.getProducts().then((response) => {
				response.data.rows.forEach(e => {
					if(e.image) {
						e.image = process.env.VUE_APP_SERVER_LINK + e.image;
					}
				})
				state.products = response.data.rows;
			}).catch((_error) => {
				createToast(i18n.global.t("panel.products.productGetError"), {
					type: "danger",
					position: "top-center",
				});
			});
		},

		getProduct({ state }, productId) {
			return ProductsService.getProduct(productId).then((response) => {
				response.data.image = process.env.VUE_APP_SERVER_LINK + response.data.image;
				state.edit = response.data;
			}).catch((_error) => {
				createToast(i18n.global.t("panel.products.productGetError"), {
					type: "danger",
					position: "top-center",
				});
			});
		},

		deleteProduct({ state }, productId) {
			return ProductsService.deleteProduct(productId)
				.then(() => {
					state.products = state.products.filter(u => u.id !== productId);
					
					createToast(i18n.global.t("panel.products.productDeleted"), {
						type: "danger",
						position: "top-center",
					});
				})
				.catch((_error) => {
					createToast(i18n.global.t("panel.products.productDeleteError"), {
						type: "danger",
						position: "top-center",
					});
				});
		},

		updateProduct({ state }, data) {
			return ProductsService.updateProduct(data.id, data)
				.then(() => {
					state.edit = data;

					createToast(i18n.global.t("panel.products.productUpdated"), {
						type: "danger",
						position: "top-center",
					});

					router.push("/products");
				})
				.catch((_error) => {
					createToast(i18n.global.t("panel.products.productSaveError"), {
						type: "danger",
						position: "top-center",
					});
				});
		},

		createProduct({ state }, data) {
			return ProductsService.createProduct(data)
				.then(() => {
					state.edit = data;

					router.push("/products");
				})
				.catch((_error) => {
					createToast(i18n.global.t("panel.products.productSaveError"), {
						type: "danger",
						position: "top-center",
					});
				});
		},

		createProducts({ state }, data) {
			return ProductsService.createProducts(data)
				.then(() => {
					state.edit = data;

					router.push("/products");
				})
				.catch((_error) => {
					createToast(i18n.global.t("panel.products.productSaveError"), {
						type: "danger",
						position: "top-center",
					});
				});
		},
	},
};
