import { createRouter, createWebHistory } from "vue-router";

import Home from "../views/Home";

const routes = [
	{
		// Document title tag
		// We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
		meta: {
			title: "Dashboard",
		},
		path: "/",
		name: "home",
		//component: Home,
		component: () => import(/* webpackChunkName: "rooms" */ "../views/ActiveRooms"),
	},
	{
		meta: {
			title: "Rooms",
		},
		path: "/rooms",
		name: "rooms",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "rooms" */ "../views/ActiveRooms"),
	},
	{
		meta: {
			title: "Booking Rooms",
		},
		path: "/booking_rooms",
		name: "booking_rooms",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "rooms" */ "../views/BookingRooms"),
	},
	{
		meta: {
			title: "History",
		},
		path: "/history",
		name: "history",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "rooms" */ "../views/History"),
	},
	{
		meta: {
			title: "Room history",
		},
		path: "/history/:roomId",
		name: "roomHistory",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "rooms" */ "../views/RoomHistory"),
	},
	{
		meta: {
			title: "Users",
		},
		path: "/users",
		name: "users",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "rooms" */ "../views/Users"),
	},
	{
		meta: {
			title: "Products",
		},
		path: "/products",
		name: "products",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "rooms" */ "../views/Products"),
	},
	{
		meta: {
			title: "Product create",
		},
		path: "/product/create",
		name: "productCreate",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "rooms" */ "../views/ProductCreate"),
	},
	{
		meta: {
			title: "Product upload",
		},
		path: "/product/upload",
		name: "productuUpload",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "rooms" */ "../views/ProductUpload"),
	},
	{
		meta: {
			title: "Product edit",
		},
		path: "/product/:productId",
		name: "productEdit",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "rooms" */ "../views/ProductEdit"),
	},
	{
		meta: {
			title: "Settings",
		},
		path: "/settings",
		name: "Settings",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "rooms" */ "../views/Settings"),
	},
	{
		meta: {
			title: "User create",
		},
		path: "/user/create",
		name: "userCreate",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "rooms" */ "../views/UserCreate"),
	},
	{
		meta: {
			title: "User edit",
		},
		path: "/user/:userId",
		name: "userEdit",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "rooms" */ "../views/UserEdit"),
	},
	{
		meta: {
			title: "Tables",
		},
		path: "/tables",
		name: "tables",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "tables" */ "../views/Tables"),
	},
	{
		meta: {
			title: "Forms",
		},
		path: "/forms",
		name: "forms",
		component: () => import(/* webpackChunkName: "forms" */ "../views/Forms"),
	},
	{
		meta: {
			title: "Profile",
		},
		path: "/profile",
		name: "profile",
		component: () => import(/* webpackChunkName: "profile" */ "../views/Profile"),
	},
	{
		meta: {
			title: "Ui",
		},
		path: "/ui",
		name: "ui",
		component: () => import(/* webpackChunkName: "profile" */ "../views/Ui"),
	},
	{
		meta: {
			title: "Login",
			formScreen: true,
		},
		path: "/login",
		name: "login",
		component: () => import(/* webpackChunkName: "login" */ "../views/Login"),
	},
];

const router = createRouter({
	mode: "history",
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		return savedPosition || { top: 0 };
	},
});

export default router;
