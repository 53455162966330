import UserService from "@/services/user.service";
import { createToast } from "mosha-vue-toastify";
import i18n from "../lang";
import router from "../router";

export const user = {
	namespaced: true,
	state: {
		users: [],
		edit: {},
		settings: {
			callbackurl: {
				slug: '',
				value: '',
			},
			retail_mod: {
				slug: 'retail_mod',
				value: false,
			},
			session_quantity_1: {
				slug: 'session_quantity_1',
				value: '',
			},
			session_quantity_2: {
				slug: 'session_quantity_2',
				value: '',
			},
			session_quantity_3: {
				slug: 'session_quantity_3',
				value: '',
			},
			session_quantity_4: {
				slug: 'session_quantity_4',
				value: '',
			},
			session_quantity_5: {
				slug: 'session_quantity_5',
				value: '',
			},
			session_quantity_6: {
				slug: 'session_quantity_6',
				value: '',
			},
			session_quantity_7: {
				slug: 'session_quantity_7',
				value: '',
			},

			working_time_1: {
				slug: 'working_time_1',
				value: '',
			},
			working_time_2: {
				slug: 'working_time_2',
				value: '',
			},
			working_time_3: {
				slug: 'working_time_3',
				value: '',
			},
			working_time_4: {
				slug: 'working_time_4',
				value: '',
			},
			working_time_5: {
				slug: 'working_time_5',
				value: '',
			},
			working_time_6: {
				slug: 'working_time_6',
				value: '',
			},
			working_time_7: {
				slug: 'working_time_7',
				value: '',
			},
			session_time: {
				slug: 'session_time',
				value: '',
			},
		},
	},
	actions: {
		getUsers({ state }) {
			return UserService.getUsers().then((response) => {
				state.users = response.data;
			});
		},

		getUser({ state }, userId) {
			return UserService.getUser(userId).then((response) => {
				state.edit = response.data;
			});
		},

		deleteUser({ state }, userId) {
			return UserService.deleteUser(userId)
				.then(() => {
					state.users = state.users.filter(u => u.id !== userId);
					
					createToast(i18n.global.t("panel.users.userDeleted"), {
						type: "danger",
						position: "top-center",
					});
				})
				.catch((_error) => {
					createToast(i18n.global.t("panel.users.userDeleteError"), {
						type: "danger",
						position: "top-center",
					});
				});
		},

		setSettings({ state }, data) {
			return UserService.setSettings(data)
				.then(() => {
					createToast(i18n.global.t("panel.settings.success"), {
						type: "danger",
						position: "top-center",
					});
				})
				.catch((_error) => {
					createToast(i18n.global.t("panel.settings.error"), {
						type: "danger",
						position: "top-center",
					});
				});
		},

		getSettings({ state }, data) {
			return UserService.getSettings(data)
				.then((response) => {
					state.settings.callbackurl = response.data;
				})
				.catch((_error) => {
				});
		},

		getAllSettings({ state }) {
			return UserService.getAllSettings()
				.then((response) => {
					for (const [key, value] of Object.entries(response.data)) {
						state.settings[key] = value;
					}
				})
				.catch((_error) => {
				});
		},

		setUserAsActive({ state }, data, route) {
			return UserService.updateUser(data.id, data)
				.then(() => {
					state.edit = data;

					router.push(route);
				})
				.catch((_error) => {
				});
		},

		updateUser({ state }, data) {
			return UserService.updateUser(data.id, data)
				.then(() => {
					state.edit = data;

					createToast(i18n.global.t("panel.users.userUpdated"), {
						type: "danger",
						position: "top-center",
					});

					router.push("/users");
				})
				.catch((_error) => {
					createToast(i18n.global.t("panel.users.userSaveError"), {
						type: "danger",
						position: "top-center",
					});
				});
		},

		createUser({ state }, data) {
			return UserService.createUser(data)
				.then(() => {
					state.edit = data;

					router.push("/users");
				})
				.catch((_error) => {
					createToast(i18n.global.t("panel.users.userSaveError"), {
						type: "danger",
						position: "top-center",
					});
				});
		},
	},
};
