import AuthService from "./auth.service";
import axios from "axios";

class ButtonService {
	getButtons() {
		return axios.get(process.env.VUE_APP_SERVER_LINK + "/buttons", { headers: AuthService.authHeader() });
	}

	updateButton(buttonId, data) {
		return axios.put(process.env.VUE_APP_SERVER_LINK + `/button/${buttonId}`, data, { headers: AuthService.authHeader() });
	}

	createButton(data) {
		return axios.post(process.env.VUE_APP_SERVER_LINK + `/button/create`, data, { headers: AuthService.authHeader() });
	}

	bindButton(data) {
		return axios.post(process.env.VUE_APP_SERVER_LINK + `/button/to/product`, data, { headers: AuthService.authHeader() });
	}

	unbindButton(data) {
		return axios.delete(process.env.VUE_APP_SERVER_LINK + `/product/${data.product_id}/button/${data.button_id}`, { headers: AuthService.authHeader() });
	}

	bindButtonCheck(data) {
		return axios.get(process.env.VUE_APP_SERVER_LINK + `/product/${data.product_id}/buttons/`, { headers: AuthService.authHeader() });
	}

	deleteButton(buttonId) {
		return axios.delete(process.env.VUE_APP_SERVER_LINK +  `/button/${buttonId}`, { headers: AuthService.authHeader() });
	}
}

export default new ButtonService();
