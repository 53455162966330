import { createToast } from "mosha-vue-toastify";
import i18n from "../lang";
import router from "../router";
import ButtonService from "../services/button.service";

export const button = {
	namespaced: true,
	state: {
		buttons: [],
		connectedBtn: [],
		edit: {},
	},
	actions: {
		getButtons({ state }) {
			return ButtonService.getButtons().then((response) => {
				response.data.rows.forEach(e => {
					if(e.icon) {
						e.icon = process.env.VUE_APP_SERVER_LINK + e.icon;
					}
				})
				state.buttons = response.data.rows;
			}).catch((_error) => {
				createToast(i18n.global.t("panel.btn.btnGetError"), {
					type: "danger",
					position: "top-center",
				});
			});
		},

		deleteButton({ state }, buttonId) {
			return ButtonService.deleteButton(buttonId)
				.then(() => {
					state.buttons = state.buttons.filter(u => u.id !== buttonId);
					
					createToast(i18n.global.t("panel.btn.btnDeleted"), {
						type: "danger",
						position: "top-center",
					});
				})
				.catch((_error) => {
					createToast(i18n.global.t("panel.btn.btnDeleteError"), {
						type: "danger",
						position: "top-center",
					});
				});
		},

		updateButton({ state }, data) {
			return ButtonService.updateButton(data.id, data)
				.then(() => {
					state.edit = data;

					createToast(i18n.global.t("panel.btn.btnUpdated"), {
						type: "danger",
						position: "top-center",
					});

					router.push("/settings");
				})
				.catch((_error) => {
					createToast(i18n.global.t("panel.btn.btnSaveError"), {
						type: "danger",
						position: "top-center",
					});
				});
		},

		createButton({ state }, data) {
			return ButtonService.createButton(data)
				.then(() => {
					state.edit = data;

					router.push("/settings");
				})
				.catch((_error) => {
					createToast(i18n.global.t("panel.btn.btnSaveError"), {
						type: "danger",
						position: "top-center",
					});
				});
		},

		bindButton({ state }, data) {
			return ButtonService.bindButton(data)
				.then(() => {
					ButtonService.bindButtonCheck(data)
						.then((response) => {
							ButtonService.getButtons().then((responseAll) => {
								responseAll.data.rows.forEach(e => {
									e.connected = false;
									if(response.data) {
										response.data.forEach(j => {
											if (e.id === j.id) {
												e.connected = true
											}
										})
									}
									if(e.icon) {
										e.icon = process.env.VUE_APP_SERVER_LINK + e.icon;
									}
								})
								state.buttons = responseAll.data.rows;
							}).catch((_error) => {
								createToast(i18n.global.t("panel.btn.btnGetError"), {
									type: "danger",
									position: "top-center",
								});
							});
						}).catch((_error) => {
						createToast(i18n.global.t("panel.btn.btnGetError"), {
							type: "danger",
							position: "top-center",
						});
					});
				})
				.catch((_error) => {
					createToast(i18n.global.t("panel.btn.btnSaveError"), {
						type: "danger",
						position: "top-center",
					});
				});
		},

		unbindButton({ state }, data) {
			return ButtonService.unbindButton(data)
				.then(() => {
					ButtonService.bindButtonCheck(data)
						.then((response) => {
							ButtonService.getButtons().then((responseAll) => {
								responseAll.data.rows.forEach(e => {
									e.connected = false;
									if (Array.isArray(response.data) && response.data) {
										response.data.forEach(j => {
											if (e.id === j.id) {
												e.connected = true
											}
										})
									}
									if(e.icon) {
										e.icon = process.env.VUE_APP_SERVER_LINK + e.icon;
									}
								})
								state.buttons = responseAll.data.rows;
							}).catch((_error) => {
								createToast(i18n.global.t("panel.btn.btnGetError"), {
									type: "danger",
									position: "top-center",
								});
							});
						}).catch((_error) => {
						createToast(i18n.global.t("panel.btn.btnGetError"), {
							type: "danger",
							position: "top-center",
						});
					});
				})
				.catch((_error) => {
					createToast(i18n.global.t("panel.btn.btnSaveError"), {
						type: "danger",
						position: "top-center",
					});
				});
		},

		bindButtonCheck({ state }, data) {
			return ButtonService.bindButtonCheck(data)
				.then((response) => {
					ButtonService.getButtons().then((responseAll) => {
						responseAll.data.rows.forEach(e => {
							e.connected = false;
							if (Array.isArray(response.data) && response.data) {
								response.data.forEach(j => {
									if (e.id === j.id) {
										e.connected = true
									}
								})
							}
							if(e.icon) {
								e.icon = process.env.VUE_APP_SERVER_LINK + e.icon;
							}
						})
						state.buttons = responseAll.data.rows;
					}).catch((_error) => {
						createToast(i18n.global.t("panel.btn.btnGetError"), {
							type: "danger",
							position: "top-center",
						});
					});
				})
				.catch((_error) => {
					createToast(i18n.global.t("panel.btn.btnSaveError"), {
						type: "danger",
						position: "top-center",
					});
				});
		},
	},
};
