import AuthService from "./auth.service";
import axios from "axios";

class ProductsService {
	getProducts() {
		return axios.get(process.env.VUE_APP_SERVER_LINK + "/products?all=true", { headers: AuthService.authHeader() });
	}

	getProduct(productId) {
		return axios.get(process.env.VUE_APP_SERVER_LINK + `/product/${productId}`, { headers: AuthService.authHeader() });
	}

	updateProduct(productId, data) {
		return axios.put(process.env.VUE_APP_SERVER_LINK + `/product/${productId}`, data, { headers: AuthService.authHeader() });
	}

	createProduct(data) {
		return axios.post(process.env.VUE_APP_SERVER_LINK + `/product`, data, { headers: AuthService.authHeader() });
	}

	createProducts(data) {
		return axios.post(process.env.VUE_APP_SERVER_LINK + `/product_json`, data, { headers: AuthService.authHeader(), 'Content-Type': 'multipart/form-data' });
	}

	deleteProduct(productId) {
		return axios.delete(process.env.VUE_APP_SERVER_LINK +  `/product/${productId}`, { headers: AuthService.authHeader() });
	}
}

export default new ProductsService();
