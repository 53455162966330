<template>
  <nav-bar />
  <aside-menu :menu="menu" />
  <router-view />

  <modal-box
    v-model="modal.opened"
    :title="modal.title || $t('panel.common.confirmTitle')"
    :has-cancel="modal.hasCancel"
    :confirm-label="modal.label || $t('panel.common.confirm')"
    :cancel-label="modal.label || $t('panel.common.cancel')"
    :button="modal.color"
    :on-confirm="modal.onConfirm"
  >
    <p>{{ modal.text }}</p>
  </modal-box>

  <footer-bar />
</template>

<script>
// @ is an alias to /src
// import { useStore } from 'vuex'
import menu from "@/menu.js";
import NavBar from "@/components/NavBar";
import AsideMenu from "@/components/AsideMenu";
import FooterBar from "@/components/FooterBar";
import ModalBox from '@/components/ModalBox';

export default {
  name: "Home",

  components: {
    FooterBar,
    AsideMenu,
    NavBar,
    ModalBox,
  },

  setup() {
    // const store = useStore()

    // store.commit('user', {
    //   name: 'Livecart admin',
    //   email: 'example@example.com',
    //   avatar: 'https://avatars.dicebear.com/v2/gridy/John-Doe.svg'
    // });

    return {
      menu,
    };
  },

  data() {
    return { socket: undefined };
  },

  computed: {
    modal() {
      return this.$store.state.modal || false;
    },
  },

  mounted() {
    
  },
};
</script>
