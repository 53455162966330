import axios from "axios";

class AuthService {
	login(user) {
		return axios.post(process.env.VUE_APP_SERVER_LINK + "/login", user).then((response) => {
			if (response.data.accessToken) {
				localStorage.setItem("user", JSON.stringify(response.data));
			}

			return response.data;
		});
	}

	logout() {
		localStorage.removeItem("user");
	}

	authHeader(type = 'api') {
		const user = JSON.parse(localStorage.getItem("user"));

		if (user && user.accessToken) {
			if (type === "api") {
				return { 'x-access-token': user.accessToken };
			} 

			if (type === "socket") {
				return { token: "Bearer " + user.accessToken };
			} 
		} else {
			return {};
		}
	}
}

export default new AuthService();
