import { auth } from "./auth.module";
import { createStore } from 'vuex';
import { room } from "./room.module";
import { user } from "./user.module";
import { product } from "./products.module";
import { button } from "./button.module";

export default createStore({
	state: {
		isFormScreen: false,
		isAsideMobileExpanded: false,
		isAsideLgActive: false,
		modal: {
			opened: false,
			title: "",
			text: "",
			hasCancel: false,
			label: "Confirm",
			color: "info",
			onConfirm: () => {},
		},
	},

	mutations: {
		basic(state, payload) {
			state[payload.key] = payload.value;
		},
		user(state, payload) {
			state.user = payload;
		},
	},

	actions: {
		asideMobileToggle({ commit, state }, payload = null) {
			const isShow = payload !== null ? payload : !state.isAsideMobileExpanded;

			document.getElementById("app").classList[isShow ? "add" : "remove"]("ml-60");

			document.documentElement.classList[isShow ? "add" : "remove"]("m-clipped");

			commit("basic", {
				key: "isAsideMobileExpanded",
				value: isShow,
			});
		},
		modal({ state }, data) {
			state.modal = data;
		},
		asideLgToggle({ commit, state }, payload = null) {
			commit("basic", { key: "isAsideLgActive", value: payload !== null ? payload : !state.isAsideLgActive });
		},
		formScreenToggle({ commit }, value) {
			commit("basic", { key: "isFormScreen", value });

			document.documentElement.classList[value ? "add" : "remove"]("form-screen");
		},
	},

	modules: {
		auth,
		room,
		user,
		product,
		button,
	},
});
