import {mdiAccountMultiple, mdiFileDocument, mdiCog, mdiAlarm, mdiForum, mdiHistory} from "@mdi/js";

import i18n from "./lang";

export default [
	i18n.global.t("panel.common.menu"),
	[
		{
			to: "/rooms",
			label: i18n.global.t("panel.common.activeRooms"),
			icon: mdiForum,
			updateMark: true,
		},
		{
			to: "/booking_rooms",
			label: i18n.global.t("panel.common.bookingRooms"),
			icon: mdiAlarm,
			updateMark: true,
		},
		{
			to: "/history",
			label: i18n.global.t("panel.common.history"),
			icon: mdiHistory,
			updateMark: true,
		},
		{
			to: "/users",
			label: i18n.global.t("panel.common.users"),
			icon: mdiAccountMultiple,
			updateMark: true,
		},
		{
			to: "/products",
			label: i18n.global.t("panel.common.products"),
			icon: mdiFileDocument,
			updateMark: true,
		},
		{
			to: "/settings",
			label: i18n.global.t("panel.settings.title"),
			icon: mdiCog,
			updateMark: true,
		},
		// {
		// 	to: "/tables",
		// 	label: "Tables",
		// 	icon: mdiTable,
		// 	updateMark: true,
		// },
		// {
		// 	to: "/forms",
		// 	label: "Forms",
		// 	icon: mdiSquareEditOutline,
		// },
		// {
		// 	to: "/ui",
		// 	label: "UI",
		// 	icon: mdiTelevisionGuide,
		// },
		// {
		// 	to: "/profile",
		// 	label: "Profile",
		// 	icon: mdiAccountCircle,
		// },
		// {
		//   to: '/login',
		//   label: 'Login',
		//   icon: mdiLock
		// },
		// {
		//   label: 'Submenus',
		//   subLabel: 'Submenus Example',
		//   icon: mdiViewList,
		//   menu: [
		//     {
		//       label: 'Sub-item One'
		//     },
		//     {
		//       label: 'Sub-item Two'
		//     }
		//   ]
		// }
	],
];
