import AuthService from "./auth.service";
import axios from "axios";

class UserService {
	getUsers() {
		return axios.get(process.env.VUE_APP_SERVER_LINK + "/users", { headers: AuthService.authHeader() });
	}

	getUser(userId) {
		return axios.get(process.env.VUE_APP_SERVER_LINK + `/user/${userId}`, { headers: AuthService.authHeader() });
	}

	updateUser(userId, data) {
		return axios.put(process.env.VUE_APP_SERVER_LINK + `/user/${userId}`, data, { headers: AuthService.authHeader() });
	}

	setSettings(data) {
		return axios.post(process.env.VUE_APP_SERVER_LINK + `/settings/set`, data, { headers: AuthService.authHeader() });
	}

	getSettings(data) {
		return axios.get(process.env.VUE_APP_SERVER_LINK + `/settings/get/${data}`, { headers: AuthService.authHeader() });
	}

	getAllSettings() {
		return axios.get(process.env.VUE_APP_SERVER_LINK + `/settings/get`, { headers: AuthService.authHeader() });
	}

	createUser(data) {
		return axios.post(process.env.VUE_APP_SERVER_LINK + `/user`, data, { headers: AuthService.authHeader() });
	}

	deleteUser(userId) {
		return axios.delete(process.env.VUE_APP_SERVER_LINK +  `/user/${userId}`, { headers: AuthService.authHeader() });
	}
}

export default new UserService();
