import { createI18n } from "vue-i18n";
import de from "./de.json";
import en from "./en.json";
import ru from "./ru.json";
import ua from "./ua.json";

export default createI18n({
	locale: localStorage.getItem("locale") || "ru",
	fallbackLocale: "en",
	messages: { en, ru, ua, de },
});
